<template>
  <v-container class="overviewContainer">
    <v-row class="hidden-sm-and-down">
      <v-img
        height="300"
        src="/img/retailerCover.jpeg"
        style="overflow: visible"
      >
        <v-row wrap class="overlay">
            <v-col cols="12">
                <v-row wrap>
                <v-col cols="12" align="center">
                    <p class="bigHeader">Retailers Deals</p>
                </v-col>
                </v-row>

                <v-row class="mt-10" style="postion:absolute; left: 44%; top: 90% ">
                <v-col cols="3" class="headerBox ml-12">
                    <v-layout wrap>
                    <v-col cols="5" align="left">
                        <v-img
                        contain
                        src="https://res.cloudinary.com/ecrtechdev/image/upload/v1632944202/5ec81f5e7964eoxynorm-20mg_2_ztgyxs.png"
                        height="120"
                        />
                    </v-col>

                    <v-col cols="7" align="right">
                        <v-icon size="50" color="#72BD61">mdi-bookmark</v-icon>
                        <v-layout align="right" style="font-size:11px;" class="mt-4">
                        Furosemid 20mg is available in dozen
                        </v-layout>
                        <v-layout wrap>
                        <v-col cols="6">
                            <h6>$2.11</h6>
                        </v-col>
                        <v-col cols="6">
                            <span class="spanPrice">-99%</span>
                        </v-col>
                        </v-layout>
                    </v-col>
                    </v-layout>
                </v-col>

                <v-col cols="3" offset="1" class="headerBox">
                    <v-layout wrap>
                    <v-col cols="5" align="left">
                        <v-img
                        contain
                        src="https://res.cloudinary.com/ecrtechdev/image/upload/v1632917108/5bb46a81e1a59furosemide-20mg_diabetis_1_op9nnh.png"
                        height="120"
                        />
                    </v-col>

                    <v-col cols="7" align="right">
                        <v-icon size="50" color="#72BD61">mdi-bookmark</v-icon>
                        <v-layout align="right" style="font-size:11px;" class="mt-4">
                        Furosemid 20mg is available in dozen
                        </v-layout>
                        <v-layout wrap>
                        <v-col cols="6">
                            <h6>$2.11</h6>
                        </v-col>
                        <v-col cols="6">
                            <span class="spanPrice">-99%</span>
                        </v-col>
                        </v-layout>
                    </v-col>
                    </v-layout>
                </v-col>

                <v-col cols="3" offset="1" class="headerBox">
                    <v-layout wrap>
                    <v-col cols="5" align="left">
                        <v-img
                        contain
                        src="https://res.cloudinary.com/ecrtechdev/image/upload/v1632314422/product5_dgi2by.png"
                        height="120"
                        />
                    </v-col>

                    <v-col cols="7" align="right">
                        <v-icon size="50" color="#72BD61">mdi-bookmark</v-icon>
                        <v-layout align="right" style="font-size:11px;" class="mt-4">
                        Furosemid 20mg is available in dozen
                        </v-layout>
                        <v-layout wrap>
                        <v-col cols="6">
                            <h6>$2.11</h6>
                        </v-col>
                        <v-col cols="6">
                            <span class="spanPrice">-99%</span>
                        </v-col>
                        </v-layout>
                    </v-col>
                    </v-layout>
                </v-col>
                </v-row>
            </v-col>
        </v-row>
      </v-img>
    </v-row>

    <!-- <v-row class="categoryDeals hidden-sm-and-down">
      <v-layout style="margin-top:100px;">
        <v-col cols="12">
          <h2 align="center">Discounted Deals</h2>
        </v-col>
      </v-layout>

      <v-row wrap class="mb-10" style="postion:absolute; left: 44%; top: 90% ">
        <v-col
          cols="3"
          class="headerBox2"
          style="margin-left:75px;"
          v-for="(product, index) in fiteredDiscounts.slice(0, 3)"
          :key="index"
        >
          <v-layout wrap>
            <v-col cols="5" align="left">
              <v-img contain :src="product.productImagePath" height="120" />
            </v-col>

            <v-col cols="7" align="center">
              <h5 style="margin-top:50px;">{{ product.discount }}% Discount</h5>
            </v-col>
          </v-layout>
        </v-col>
      </v-row>
    </v-row> -->

    <v-container class="hidden-md-and-up">
      <v-row>
        <v-col cols="12">
          <h2 align="center">Season's Buys</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="headerBox">
          <v-layout wrap>
            <v-col cols="5" align="left">
              <v-img
                contain
                src="https://res.cloudinary.com/ecrtechdev/image/upload/v1632944202/5ec81f5e7964eoxynorm-20mg_2_ztgyxs.png"
                height="120"
              />
            </v-col>

            <v-col cols="7" align="right">
              <v-icon size="50" color="#72BD61">mdi-bookmark</v-icon>
              <v-layout align="right" style="font-size:11px;" class="mt-4">
                Furosemid 20mg is available in dozen
              </v-layout>
              <v-layout wrap>
                <v-col cols="6">
                  <h6>$2.11</h6>
                </v-col>
                <v-col cols="6">
                  <span class="spanPrice">-99%</span>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>

    <v-row class="mt-8">
      <v-col cols="12">
        <h2 align="center">New Arrivals</h2>
      </v-col>
    </v-row>

    <v-row class="mb-8">
      <v-col
        v-for="(product, index) in productLists"
        :key="index"
        cols="6"
        md="2"
        class="mt-3"
      >
        <v-sheet
          height="200"
          style="background-color:#C4C4C4;border-radius:10px;"
        >
          <router-link
            :to="'/product-detail/' + product.productId"
            style="text-decoration: none !important;"
          >
            <v-img
              height="120"
              :src="product.productImagePath"
              full
              style="background-color:#ffffff;"
            >
              <h6
                align="center"
                class="priceStyle2"
                v-if="product.discount >= 1"
              >
                {{ product.discount }} %
              </h6>
            </v-img>
          </router-link>

          <div class="mt-3 pl-5" style="background-color:#C4C4C4">
            <span>{{ product.name }}</span>
            <h6>
              {{ systemSetting.systemCurrency }}
              {{ product.price }}
              <br />

              <strike
                style="font-size:14px;color:grey !important;"
                align="right"
              >
                {{ systemSetting.systemCurrency }}
                {{ product.price * 2 }}
              </strike>
            </h6>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "DistributorssStores",
  data() {
    return {
      discountedProducts: [],
      productLists: [],
      systemSetting: {
        systemCurrency: "₦",
      },
    };
  },
  computed: {
    fiteredDiscounts: function() {
      return this.discountedProducts.filter(function(el) {
        return el.discount >= 1;
      });
    },
  },
  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          console.log(response);
          this.productLists = response.data.data;
          this.discountedProducts = response.data.data;
        });
    },
  },
};
</script>
<style scoped>
.overviewContainer {
  background-color: #f2f2f2;
  padding: 10px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.bigHeader {
  font-size: 50px;
  color: #ffffff;
  margin-top: 20px;
}

.headerBox {
  border: 2px solid green;
  height: 200px;
  background-color: #ffffff;
  border-radius: 10px;
}

.headerBox2 {
  border: 1px solid green;
  height: 150px;
  background-color: #ffffff;
  border-radius: 10px;
}

.spanPrice {
  background-color: red;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 500;
}

.categoryDeals {
  background-color: #c4c4c4;
  height: 250px;
  margin-bottom: 100px;
  overflow: visible;
}

.priceStyle2 {
  width: 60px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 2px;
  border-bottom-right-radius: 5px;
}
</style>
